import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// import this new file
import { MaterialModule } from "./material/material.module";

import { TokenInterceptor } from "./http-interceptor/token-interceptor";
import { AppInitService } from "./services/app-init/app-init.service";
import { BlobService } from "angular-azure-blob-service";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LogoutPopupComponent } from "./logout-popup/logout-popup.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgxImageCompressService } from "ngx-image-compress";
import { AutoLogoutService } from "./services/autologout/auto-logout.service";
import { PubNubAngular } from "pubnub-angular2";
import { LoaderComponent } from "./loader/loader.component";

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

export function init_event(autoLogoutService: AutoLogoutService) {
  return () => autoLogoutService.initListener();
}

@NgModule({
  declarations: [AppComponent, LogoutPopupComponent, LoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
  ],
  entryComponents: [LogoutPopupComponent],
  providers: [
    BlobService,
    AppInitService,
    NgxImageCompressService,
    AutoLogoutService,
    PubNubAngular,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_event,
      deps: [AutoLogoutService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
