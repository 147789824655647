<div class="mati-popup">
  <div class="circle-icon">
    <i class="icon-logout"></i>
  </div>
  <h2 mat-dialog-title>Auto Logout</h2>
  <mat-dialog-content>
    <div class="text-center">
      You will be auto logout in 30 sec. Click cancel to continue.
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="text-center">
      <button
        class="mat-raised-button btn btn-primary text-center"
        (click)="close()"
      >
        Cancel to continue
      </button>
    </div>
  </mat-dialog-actions>
</div>
