import { Injectable } from "@angular/core";
import { DispatcherService } from "src/app/service-dispatcher/dispatcher.service";
import { tap, map, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../encryption-service/encryption.service";
import { Observable, throwError } from "rxjs";

declare var window: any;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // envConfig = window.config;
  // baseUrl = window.config.baseUrl;

  private readonly JWT_TOKEN = "token";
  private readonly REFRESH_TOKEN = "refreshToken";
  private readonly USER_ID = "userId";
  private readonly EMAIL_ID = "email";
  private readonly ROLE = "roles";
  private readonly LASTACTION = "lastAction";
  private openRoute = "";
  private LOGIN_USERID = "";

  constructor(
    private dispatcherService: DispatcherService,
    private encrptionService: EncryptionService,
    private http: HttpClient
  ) {}

  /**
   * login
   * admin login
   * @param request
   */
  login(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .post(`${window.config.baseUrl}/login`, request)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * refreshToken
   * refresh Token
   */
  refreshToken(): Observable<any> {
    return this.http
      .post<any>(`${window.config.baseUrl}/login`, {
        refresh_token: this.getRefreshToken(),
        grant_type: "refresh_token",
      })
      .pipe(
        map((tokens) => {
          if (tokens && tokens.data.access_token) {
            this.storeJwtToken(tokens.data);
          }
          return tokens;
        }),
        throwError
      );
  }

  /**
   * verifyToken
   * @param token
   */
  verifyToken(token) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.get(`${window.config.baseUrl}/verifyLink`, { verificationToken: token }).subscribe((res: any) => {
    //     resolve(res);
    //   }, (err) => {
    //     reject(err);
    //   });
    // });
  }

  /**
   * forgetPassword
   * forgot password
   * @param request
   */
  forgetPassword(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .post(`${window.config.baseUrl}/forget-password`, request)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * authenticateLogin
   * To authenticate admin user login
   * @param request
   */
  authenticateLogin(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .get(
          `${window.config.baseUrl}/authenticate/${request.userId}/${request.otp}`
        )
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * resendLoginOtp
   * To resend admin user login otp
   * @param request
   */
  resendLoginOtp(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .put(`${window.config.baseUrl}/resend-authentication-otp`, request)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * resetPassword
   * reset password
   * @param request
   */
  resetPassword(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .put(`${window.config.baseUrl}/reset-password`, request)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * verifyForgotPasswordLink
   * verify forgot password link
   * @param guid
   */
  verifyForgotPasswordLink(guid) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .post(`${window.config.baseUrl}/verify-forgot-password-link`, guid)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * verifyOtp
   * verify OTP
   * @param params
   */
  verifyOtp(params) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .get(`${window.config.baseUrl}/verify-otp`, params)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * verifyUserEmailLink
   * description : user for app user verify link
   * @param params
   */
  verifyUserEmailLink(request) {
    return new Promise((resolve, reject) => {
      this.dispatcherService
        .get(`${window.config.baseUrl}/email-verification/`, request)
        .subscribe(
          (res: any) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * verifyUserResetPasswordLink
   * description : verify User Reset Password Link
   * @param params
   */
  verifyUserResetPasswordLink(params) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.post(`${window.config.baseUrl}/verify-forget-password-link`, params).subscribe((res: any) => {
    //     if (res.status) {
    //       resolve(res);
    //     } else {
    //       reject(res);
    //     }
    //   }, (err) => {
    //     reject(err);
    //   });
    // });
  }

  /**
   * resetUserPassword
   * description : Reset User Password
   * @param params
   */
  resetUserPassword(params) {
    // return new Promise((resolve, reject) => {
    //   this.dispatcherService.post(`${window.config.baseUrl}/set-password`, params).subscribe((res: any) => {
    //     if (res.status) {
    //       resolve(res);
    //     } else {
    //       reject(res);
    //     }
    //   }, err => {
    //     reject(err);
    //   });
    // });
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private getUserId() {
    return this.encrptionService.decryptUsingAES256(
      localStorage.getItem(this.USER_ID)
    );
  }

  private storeJwtToken(jwts) {
    localStorage.setItem(this.JWT_TOKEN, jwts.access_token);
    localStorage.setItem(this.REFRESH_TOKEN, jwts.refresh_token);
  }

  public storeUserInfo(data) {
    localStorage.setItem(this.JWT_TOKEN, data.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, data.refreshToken);
    // localStorage.setItem(this.EMAIL_ID, data.emailId);
    // localStorage.setItem(this.USER_ID, data.userId);
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public removeTokens() {
    localStorage.removeItem(this.USER_ID);
    localStorage.removeItem(this.EMAIL_ID);
    localStorage.removeItem(this.ROLE);
    localStorage.removeItem(this.LASTACTION);
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  setOpenedRoute(url) {
    this.openRoute = url;
  }

  getOpenedRoute() {
    return this.openRoute;
  }

  setLoginId(id) {
    this.LOGIN_USERID = id ? id : "";
  }

  getLoginId() {
    return this.LOGIN_USERID ? this.LOGIN_USERID : null;
  }
}
