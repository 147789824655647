import { Component } from "@angular/core";
import { environment } from "../environments/environment";
import { AutoLogoutService } from "./services/autologout/auto-logout.service";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { CommonServiceService } from "./admin/shared/services/common-service.service";
import { HttpClient } from "@angular/common/http";
import { Router, NavigationEnd } from "@angular/router";
import { filter, map } from "rxjs/operators";
declare const $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  userInfo: any = {};
  title = "admin";
  storeKey = environment.STORE_KEY;

  constructor(private router: Router) {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        setTimeout(() => {
          var colCount = 0;
          $("tr.count-tr th").each(function () {
            if ($(this).attr("colspan")) {
              colCount += +$(this).attr("colspan");
            } else {
              colCount++;
            }
          });
          var abcwidth = [];
          $("tr.count-tr th").each(function () {
            abcwidth.push($(this).outerWidth());
          });
          var i = 0;
          $(".update-colspan>td tr.filter-row td").each(function () {
            $(this).css("width", abcwidth[i]);
            i++;
          });
          $(".update-colspan>td").attr("colspan", colCount);
        }, 1500);
      }
    });

    localStorage.setItem("lastAction", Date.now().toString());
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}
}
