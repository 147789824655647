import { Injectable } from "@angular/core";
import { DispatcherService } from "../../../../app/service-dispatcher/dispatcher.service";
import { environment } from "../../../../environments/environment";
import * as jwt from "jwt-decode";
import * as moment from "moment";
declare var window: any;
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class CommonServiceService {
  baseUrl = environment.baseUrl;
  //dynamic link build
  dynamicLinkDomain = environment.dynamicLinkDomain;
  androidPackageName =  environment.androidPackageName;
  iosBundleId = environment.iosBundleId;
  iosAppStoreId =environment.iosAppStoreId;
  firebaseKey = environment.firebaseKey;
  aflBaseUrl = environment.aflBaseUrl;
  iflBaseUrl = environment.iflBaseUrl;

  static caches = {};

  constructor(private dispatcher: DispatcherService) {}

  /**
   * getShortLink
   * description : get dynamic Short Link from code
   * @param code
   */
  public getShortLink = async (code: string): Promise<string> => {
    if (caches[code]) {
      return caches[code];
    }

    const query = {
      link: `https://exeeria.com?code=${code}`,
      apn: this.androidPackageName,
      ibi: this.iosBundleId,
      afl: `${this.aflBaseUrl}/?${code}`,
      ifl: `${this.iflBaseUrl}/?${code}`,
      efr: 1
    }

    const queryString = Object.keys(query).map(key => key + '=' + encodeURIComponent(query[key])).join('&');

    const request = {
      longDynamicLink: `https://${this.dynamicLinkDomain}/?${queryString}`,
      suffix: {
        option: "SHORT",
      },
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(request),
    };

    try {
      const promise = fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${this.firebaseKey}`,
        requestOptions
      ).then(res => res.json()).then(res => res.shortLink).then(link => link + `?code=${code}&link=${encodeURIComponent(query.link)}`);

      caches[code] = promise;

      const result = await promise;

      return result;
    } catch (error) {
      delete caches[code];
      throw new Error(error);
    }
  };

  /**
   * getLinkandAppParams
   * description : get dynamic link url and app params
   */
  getLinkandAppParams() {
    const subDomainLink = `https://${this.dynamicLinkDomain}/?link=`;
    const deepLink = "https://exeeria.com?code=";
    const appParams = `&apn=${this.androidPackageName}&ibi=${this.iosBundleId}&isi=${this.iosAppStoreId}`;
    return { subDomainLink, deepLink, appParams };
  }

  /**
   * getClinicScheduleAppoinmentByDate
   * description : get clinic schedule upcoming appoinment
   * @param request
   */
  getClinicScheduleAppoinmentByDate(request) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + "/physician-number").subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * getPhysicianAppoinmentByDate
   * description : get appoinment list by date
   * @param request
   */
  getPhycicianAppoinmentByDate(date) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseUrl + "/physician-appointment-by-date", { date: date })
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * getPatientAppoinmentByDate
   * description : get appoinment list by date
   * @param request
   */
  getPatientAppoinmentByDate(date) {
    return new Promise((resolve, reject) => {
      this.dispatcher
        .get(this.baseUrl + "/patient-appointment-by-date", { date: date })
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * isFileImage
   * description : check if file is of type image
   */
  isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }

  /**
   * getAdminRole
   * description : To get all role list
   */
  getAdminRole(roleId: string): any {
    this.dispatcher
      .get(`${environment.baseUrl}/permission/admin-role/${roleId}`, {})
      .subscribe((res: any) => {
        if (!localStorage.getItem("roles")) {
          localStorage.setItem("roles", JSON.stringify(res.data));
        } else {
          return;
        }
      });
  }

  /**
   * decode jwt token
   */
  decodeToken() {
    try {
      return jwt(localStorage.getItem("token"));
    } catch (e) {
      // console.log(e);
      return null;
    }
  }

  /**
   * decode jwt token
   */
  formatText(text) {
    try {
      const arr = text ? text.split(" ") : [];
      const newArr = arr.map((obj) => {
        return obj.charAt(0).toUpperCase();
      });
      return newArr.join(" ");
    } catch (e) {
      return "";
    }
  }

  /**
   * decode jwt token
   */
  formatObjText(obj) {
    const keys = ["firstName", "lastName", "middleName", "name", "countryName"];
    try {
      for (var key in obj) {
        if (obj.hasOwnProperty(key) && key == "firstName") {
          const arr = obj[key] ? obj[key].split(" ") : [];
          const newArr = arr.map((val) => {
            return val.charAt(0).toUpperCase();
          });
          obj[key] = newArr.join(" ");
        }
      }
      return obj;
    } catch (e) {
      return "";
    }
  }

  /**
   * adjustColumn()
   * To adjjust filter column
   */
  adjustColumn() {
    setTimeout(() => {
      var colCount = 0;
      $("tr.count-tr th").each(function () {
        if ($(this).attr("colspan")) {
          colCount += +$(this).attr("colspan");
        } else {
          colCount++;
        }
      });
      var abcwidth = [];
      $("tr.count-tr th").each(function () {
        abcwidth.push($(this).outerWidth());
      });
      var i = 0;
      $(".update-colspan>td tr.filter-row td").each(function () {
        $(this).css("width", abcwidth[i]);
        i++;
      });
      $(".update-colspan>td").attr("colspan", colCount);
    }, 200);
  }

  /**
   * formatEditorText()
   *
   */
  formatEditorText(paragraphs: any[]) {
    const formattedPara = paragraphs.map((p) => {
      if (p.content) {
        const arr = p.content ? p.content.split("<p>&nbsp;</p>").reverse() : [];
        arr.every((element, i) => {
          if (arr === "<p>&nbsp;</p>") {
            arr.splice(i, 1);
            return true;
          } else {
            return false;
          }
        });
        p.content = arr.reverse().join();
      }
      return p;
    });
    return formattedPara;
  }

  formatDate(date, format = "YYYY-MM-DD") {
    // console.log(format)
    try {
      return moment(date).local().format(format);
    } catch (e) {}
  }

  /**
   * objectComparisonFunction()
   * @param option
   * @param value
   * @returns
   */
  objectComparisonFunction(option, value): boolean {
    if (option && option.hasOwnProperty("countryId")) {
      return option.countryId == value.countryId;
    } else if (option && option.hasOwnProperty("specialityId")) {
      return option.specialityId == value.specialityId;
    } else if (option && option.hasOwnProperty("cityId")) {
      return option.cityId == value.cityId;
    } else if (option && option.hasOwnProperty("subSpecialityId")) {
      return option.subSpecialityId == value.subSpecialityId;
    } else if (option && option.hasOwnProperty("centerId")) {
      return option.centerId == value.centerId;
    } else if (option && option.hasOwnProperty("_id")) {
      return option._id == value._id;
    } else if (option && option.hasOwnProperty("id")) {
      return option.id == value.id;
    } else if (option && option.hasOwnProperty("code")) {
      return option.code == value.code;
    } else if (option && option.hasOwnProperty("brandName")) {
      return option.value == value.value;
    }
  }
}
