import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

const MINUTES_UNITL_AUTO_LOGOUT = 5; // in mins
const MINUTES_UNITL_CLOCK_LOGOUT = 1; // in mins
const CHECK_INTERVAL = 15000; // in ms
var STORE_KEY = "lastAction";

import { LogoutPopupComponent } from "src/app/logout-popup/logout-popup.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AutoLogoutService {
  isPopupOpen = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction) {
    localStorage.setItem(STORE_KEY, lastAction);
  }

  initListener() {
    document.body.addEventListener("click", () => this.reset());
    // document.body.addEventListener('mouseover', () => this.reset());
    // document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener("keydown", () => this.reset());
    document.body.addEventListener("keyup", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
  }

  reset() {
    this.isPopupOpen = false;
    this.setLastAction(Date.now().toString());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    try {
      if (localStorage.getItem("token")) {
        const now = Date.now();
        const timeleft = this.getLastAction() + 5 * 60 * 1000;

        const diff = timeleft - now;
        const isTimeout = diff < 0;
        // if (isTimeout && this.auth.loggedIn)
        if (isTimeout) {
          this.isPopupOpen = false;
          localStorage.clear();
          localStorage.setItem('returnUrl',this.router.url)
          this.closePop();
          // console.log('>>>>>>>>>>>>>. here')
          this.router.navigate(["/"]);
        } else if (diff <= 30000) {
          // diff <= 300000
          if (this.isPopupOpen === false) {
            this.isPopupOpen = true;
            // Swal.fire("Are you not there",`You will auto logout in ${MINUTES_UNITL_CLOCK_LOGOUT} min` ,)
            this.openDialog();
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  openDialog() {
    this.closePop();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(LogoutPopupComponent, dialogConfig);
  }

  closePop() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(LogoutPopupComponent, dialogConfig);
    this.dialog.closeAll();
  }
}
