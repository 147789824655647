import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoaderState } from "./loader";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  // tslint:disable-next-line: no-angle-bracket-type-assertion
  private loaderSubject = new BehaviorSubject(<LoaderState>{});
  loaderState = this.loaderSubject.asObservable();
  constructor() {}
  show() {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}
