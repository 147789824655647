export const Permission = {
  DOCTOR_ACTION_ACTIVE_ACCOUNT: 3,
  DOCTOR_ACTION_INACTIVE_ACCOUNT: 3,
  DOCTOR_ACTION_EDIT_NAME: 3,
  DOCTOR_ACTION_UPLOAD_CV: 2,
  DOCTOR_ACTION_ASSIGN_DOCTOR_QR_CODE: 2,
  DOCTOR_ACTION_DELETE_DOCTOR_QR_CODE: 2,
  DOCTOR_ACTION_BLOCK_REGISTRATION: 3,
  DOCTOR_VIEW_PENDING: 3,
  DOCTOR_NOTIFICATION_NEW_DR_REGISTRATION: 0,
  DOCTOR_VIEW_DOCTOR: 2,
  DOCTOR_VIEW_KPI: 3,
  DOCTOR_LOGIN_DATA_ACTION_RETRIEVE: 1,
  DOCTOR_BIO_EDIT_ACTION_ADD: 1,
  DOCTOR_BIO_EDIT_ACTION_EDIT: 1,
  DOCTOR_BIO_EDIT_VIEW_PENDING_FOR_APPROVAL: 1,
  DOCTOR_BIO_EDIT_NOTIFICATION_PENDING_BIO_EDIT: 0,
  DOCTOR_BIO_EDIT_ACTION_APPROVE: 2,
  DOCTOR_BIO_EDIT_ACTION_DISAPPROVE: 2,
  DOCTOR_BIO_EDIT_VIEW_DOCTOR_LIST: 2,
  DOCTOR_BIO_EDIT_VIEW_DOCTOR_LIST_FILTER: 3,

  DOCTOR_TOPIC_ACTION_CREATE_AND_ASSIGN_QR_CODE: 2,
  DOCTOR_TOPIC_ACTION_ADD_NEW_TOPIC: 1,
  DOCTOR_TOPIC_ACTION_EDIT_EXISTING_TOPIC: 1,
  DOCTOR_TOPIC_ACTION_EDIT_TQ: 1,
  DOCTOR_TOPIC_ACTION_LINK_QR_CODE: 1,
  DOCTOR_TOPIC_ACTION_MAX_NUMBER_OF_TOPICS: 2,
  DOCTOR_TOPIC_ACTION_MAX_NUMBER_OF_TOPIC_QUESTIONS: 2,
  DOCTOR_TOPIC_VIEW_LIST_OF_TOPICS: 2,
  DOCTOR_TOPIC_VIEW_List_OF_TOPIC_SORTED_PLUS_FILTER: 3,
  DOCTOR_ACTIVE_INACTIVE: 3,

  ADMIN_MESSAGING_ACTION_SEND_MESSAGE_TO_DOCTOR: 2,
  ADMIN_MESSAGING_VIEW__RECIEVE_MESSAGES: 2,

  EDIT_STATIC_PRIVACY_POLICY_EDIT: 3,
  EDIT_STATIC_TERM_AND_CONDITION_EDIT: 3,

  PATIENT_ACTION_INACTIVE_ACCOUNT: 2,
  PATIENT_ACTION_DELETE_ACCOUNT: 3,
  PATIENT_VIEW_ACCOUNT_LIST: 3,
  PATIENT_VIEW_PATIENT_LIST: 2,
  PATIENT_VIEW_FILTER_PATIENT_LIST: 2,
  PATIENT_LOGIN_DATA_ACTION_RETRIEVE: 1,
  PATIENT_ACTIVE_INACTIVE: 3,

  ACTION_ADD_ADMIN: 3,
};
