import { Injectable, Injector } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import Fingerprint2 from "fingerprintjs2";
import { DispatcherService } from "../../service-dispatcher/dispatcher.service";
import { CommonServiceService } from "src/app/admin/shared/services/common-service.service";
import { SwalConfirm, ToastMessage } from "src/app/toast/toast";
import { Permission } from "./../../../../permission";
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  permission = Permission;
  userInfo: any = {};
  constructor(
    private http: HttpClient,
    private injector: Injector,
    private dispatcher: DispatcherService,
    private commonService: CommonServiceService
  ) {}
  // Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)
  public init() {
    return new Promise((resolve, reject) => {
      Fingerprint2.getV18((result, components) => {
        window.config = {
          emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, ///^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          phoneNumber: /^(\+\d{1,3}[- ]?)?\d{8,15}$/,
          paginationMaxSize: 5,
          paginationDirectionLinks: true,
          paginationAutoHide: false,
          paginationResponsive: true,
          itemsPerPage: 20,
          currentPage: 1,
          deviceId: result,
          countryCodes: ["+966"],
          directoryBaseURL: environment.thumbnailImageURL,
          thumbnailImageURL: environment.thumbnailImageURL,
          originalImageURL: environment.directoryBaseURL,
          pubnubEnv: environment.pubnubEnv,
          doctorRoleId: "5ec691a2229d764794a918b4",
          patientRoleId: "5ec6591e49432b28b0255ceb",
          clinicDir: "clinic",
          baseUrl: environment.baseUrl,
          multiSelectLength: 3,
          azStorageAccount: "usermediauploads",
          azContainerName: "images",
          // azContainerName: 'mediaupload',
          // azThumbnailContainerName: 'images-thumbnail',
          azThumbnailContainerName: "thumbnails",
          AesSecretKey: "576D5A7134743777217A25432A462D4A",
          AesSecretIVKey: "4N6P7Q9S2J4M5N6Q",
          ClientSecret:
            "ZTJOc2FXVnVkRWxrT2lJaUxDQnVZVzFsT2lKbGNHbGpaMlZ0Y3kxaFpHMXBiaUo5OmUyTnNhV1Z1ZEVsa09pSWlMQ0J1WVcxbE9pSmxjR2xqWjJWdGN5MWhaRzFwYmlKOQ==",
        };
      });
      // this.getAdminRole()
      resolve(true);
    });
  }

  checkAdminRole(activatyName, text = "does not have access to it") {
    return true;
    this.userInfo = this.commonService.decodeToken();
    let permission = JSON.parse(localStorage.getItem("roles"));
    if (permission) {
      const value = permission.filter((x) => x.activity === activatyName);
      if (value.length) {
        if (this.userInfo.permission >= value[0].access) {
          return true;
        } else {
          SwalConfirm({
            title: "Unauthorize Access",
            text:
              `${this.adminRoleName(this.userInfo.permission)}` +
              ` ` +
              `${text}`,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#1F467E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
          return false;
        }
      } else {
        if (this.userInfo.permission >= this.permission[activatyName]) {
          return true;
        } else {
          SwalConfirm({
            title: "Unauthorize Access",
            text:
              `${this.adminRoleName(this.userInfo.permission)}` +
              ` ` +
              `${text}`,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#1F467E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
        return false;
      }
    }
  }

  adminRoleName(value) {
    if (value === 3) {
      return "Super admin";
    } else if (value === 2) {
      return "Supervisor";
    } else if (value === 1) {
      return "Plateform manager";
    }
  }
}
