import { ToastMessage } from "./../toast/toast";
import { catchError, map } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EncryptionService } from "../services/encryption-service/encryption.service";

@Injectable({
  providedIn: "root",
})
export class DispatcherService {
  constructor(
    private http: HttpClient,
    private _encryptionService: EncryptionService
  ) {}

  /**
   * get method
   * @param url api url
   * @param data api data
   * @param header api header
   */
  get(url, data = {}, header = {}): Observable<any> {
    return this.http.get(url, { headers: header, params: data }).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  decyptedResponse(response) {
    if (response.result && Array.isArray(response.result.data)) {
      (response.result.data || []).map((x, i) => {
        response.result.data[i] = this._encryptionService.decryptResponse(
          x,
          Object.keys(x)
        );
      });
    } else if (response.result && Array.isArray(response.result)) {
      (response.result || []).map((x, i) => {
        response.result[i] = this._encryptionService.decryptResponse(
          x,
          Object.keys(x)
        );
      });
    } else if (Object.keys(response.result).length) {
      response.result = this._encryptionService.decryptResponse(
        response.result,
        Object.keys(response.result)
      );
    }
    return response;
  }

  /**
   * post method
   * @param url api url
   * @param data api data
   * @param headers api header
   */
  post(url, data = {}, headers = {}) {
    return this.http
      .post(url, data, headers)
      .pipe(catchError(this.handleError));
  }

  /**
   * put method
   * @param url api url
   * @param data api data
   * @param headers api header
   */
  put(url, data = {}, headers = {}) {
    return this.http.put(url, data, headers).pipe(catchError(this.handleError));
  }

  /**
   * put method
   * @param url api url
   * @param data api data
   * @param headers api header
   */
  putpdf(url, data = {}, headers = {}) {
    return this.http.put(url, data, headers);
  }

  /**
   * delete method
   * @param url api url
   * @param data api data
   */
  delete(url, body = null, params = {}) {
    const options = {
      params: !body ? params : {},
      body,
    };
    return this.http.delete(url, options).pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = error?.error?.message
        ? error.error.message
        : `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    ToastMessage.fire({ title: msg, icon: "error" });

    return throwError(msg);
  }
}
