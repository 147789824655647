import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// import {MatDialog, MatDialogConfig} from "@angular/material";

@Component({
  selector: "app-logout-popup",
  templateUrl: "./logout-popup.component.html",
  styleUrls: ["./logout-popup.component.css"],
})
export class LogoutPopupComponent implements OnInit {
  description: string;
  constructor(private dialogRef: MatDialogRef<LogoutPopupComponent>) {}

  ngOnInit(): void {}

  save() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
